
  .faq-box {
    padding-top: 32px;
    padding-bottom: 32px;
    align-items: center;
  }

  /* Reduce padding for mobile screens */
  @media (max-width: 992px) {
    .faq-box {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  
  .faq-box:not(:last-child) {
    border-bottom: 1px solid #EAEAEB;
  }
    
  .answer {
    color: #767C8F;
    font-weight: 400;
    margin-top: 20px;
  }
  
  .answer.hidden {
    display: none;
  }
  
  .icon {
    cursor: pointer;
    width: 2.6rem;
    height: 2.6rem;
  }
  
  .icon:hover {
    padding: 0.1rem;
    background-color: black;
    border-radius: 50%;
  }
  
 