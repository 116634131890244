@import "../../../styles/variables.scss";

.InputPhoneNumberStyle {
  margin-bottom: 1rem;
  > section {
    > input {
      font-size: 15px;
      width: 100%;
      border-radius: 8px !important;
      box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
    }
  }
}

.otpInputField {
  > input {
    width: 14% !important;
    margin-right: 10px;
    border: 1px solid $gray-300;
    border-radius: 12px !important;
    height: 48px;
  }
}

.flagPosition{
  position: absolute;
  top: 33px;
  left: 16px;
  z-index: 10;
}

.mobileNumberInput {
  input {
      padding: 10px 16px 10px 16px;
  }
}

.mobileNumberFlag{
  height: 20px;
  width: 30px;
}

.genericInput {
  >section:first-child {
      height: 56px;
      margin-top: 2%;
  }
  input {
      font-size: 16px !important;
      border-radius: 12px !important;
      height: 100%;
      width:95%;
  }
  div {
      font-size: 14px !important;
      margin-top: 2px !important;
      margin-right: 4px !important;
  }
}

.loginModal {
  height: 600px;
  width: 400px;
}

.modalContent {
  width: 540px;
  min-height: max-content;
  max-height: 712px;
  background: #F4F6FB;
  border-radius: 24px;
  @media (max-width: 992px) {
      width: -webkit-fill-available;
      width: -moz-available;
      width: fill-available;
  }
}

.modalRadius {
  >div:nth-child(2){
      border-radius: 24px;

      @media (max-width: 992px) {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
  }
}

@media (max-width: 992px) {
  .modalRadius {
      >div:nth-child(2){
          width: -webkit-fill-available;
          position: absolute;
          bottom: 0;
      }
  }
}