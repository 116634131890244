@import "../../../styles/variables.scss";
.searchableInput {
    label {
        margin-left: 30px;
    }
    input {
       
        padding-left: 40px !important;
        border: 1px solid #E6E9EF;
        width: 100%;
    }
    input:hover {
        border: 2px solid $red-500;
    }
    input:focus {
        border: 2px solid $red-500;
    }
}

.modalClass{
    padding: 20px;
}

.upImage {
    transform: rotate(-90deg);
}

.downImage {
    transform: rotate(90deg);
}



.img22{
    width: 22px;
    height: 22px;
}

.img20{
    width: 20px;
    height: 20px;
}

.padding24pxTopBottom {
    padding-top: 18px;
    padding-bottom: 18px;
    @media (max-width: 992px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.searchableInput{
    label {
        margin-left: 30px;
    }
    input{
        border-radius: 100px !important;
        padding-left: 40px !important;
        border: 1px solid #E6E9EF;
    }
    input:hover{
        border: 2px solid $red-500;
    }
    input:focus{
        border: 2px solid $red-500;
    }
}

.greyColor {
    color: #AFB4C0
}

.checkBoxDisabled{
    input {
        border: 2px solid #D0D4DC;
        background: $white;
    }
    input:checked{
        border: 2px solid #D0D4DC;
    }
    input:hover{
        background: $white;
        border: 2px solid #D0D4DC !important;
    }
    input:disabled{
        background: $white;
        border: 2px solid #D0D4DC;
    }
}

.dataBox {
    background: $white;
    border-radius: 20px;
    height: 540px;
    overflow-y: auto;
    margin: 0px 24px 2px 24px;
        @media (max-width: 992px) {
            height: 54vh;
        }
}

.dataBoxNonSearch{
    background: $white;
    border-radius: 20px;
    min-height: fit-content;
    max-height: 704px;
    overflow-y: auto;
    margin: 0px 24px 24px 24px;
}

.borderRadius0 {
    border-radius: 0px;
}

.boxShadowTop {
    box-shadow: 0px 2px 16px rgba(28, 28, 28, 0.04);
}

.pd24 {
    padding: 24px;
}

.px24{
    padding: 0px 24px 0px 24px;
}

.paddingBottom0{
    padding-bottom: 0;
}

.borderDotted{
    border-bottom: 1.5px dashed #F5F5F5;
}

.wd50{
    width: 50%;
}

.checkBoxFilter{
    input {
        border: 2px solid $red-500;
    }
    input:checked{
        background: $red-500;
        border: 2px solid $red-500;
    }
    input:hover{
        border: 2px solid $red-500;
    }
}

.img200px {
    height: 200px;
    width: 200px;
}

.noContentDataBox{
    background-color: #F4F6FB;
}

.indigoColor {
    color: $indigo-500;
    opacity: 0.5,
}

@media (max-width: 992px) {
    .filterDiv::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}

.img32{
    height: 32px;
    width: 32px;
}

.img16{
    height: 16px;
    width: 16px;
}

.marginBottom65px {
    margin-bottom: 65px;
}

.bgWhite{
    background-color: white;
}

.topHeaderBgWhite {
    background-color: white;
    border-radius: 24px 24px 0px 0px;
}

.modalContent {
    width: 540px;
    min-height: max-content;
    max-height: 712px;
    border-radius: 24px;
    @media (max-width: 992px) {
        width: -webkit-fill-available;
        width: -moz-available;
        width: fill-available;
    }
  }
  
  .modalRadius {
    >div:nth-child(2){
        border-radius: 24px;
    }
  }
  
  @media (max-width: 992px) {
    .modalRadius {
        >div:nth-child(2){
            width: -webkit-fill-available;
            position: absolute;
            bottom: 0;
        }
    }
  }