@import "styles/grid.scss";
@import "styles/buttons.scss";
@import "styles/variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

//font-sizes
.fs-0 {
  font-size: 0;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-40 {
  font-size: 40px;
}

.fs-18-resp {
  font-size: 18px;
  @media only screen and (max-width: $md-screen) {
    font-size: 12px;
  }
}

.fs-20 {
  font-size: 20px;
}

.fs-15 {
  font-size: 15px;
}

.fs-19 {
  font-size: 19px;
}

.fs-30 {
  font-size: 30px;
}

.fs-36 {
  font-size: 36px;
}

.fs-21 {
  font-size: 21px;
}

.fs-20-resp {
  font-size: 20px;
  @media only screen and (max-width: $md-screen) {
    font-size: 14px;
  }
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-44 {
  font-size: 44px;
}

.fs-44-resp {
  font-size: 44px;
  @media only screen and (max-width: 720px) {
    font-size: 32px;
  }
}

.fs-32-resp {
  font-size: 32px;
  @media only screen and (max-width: 720px) {
    font-size: 28px;
  }
}

.w-200 {
  font-family: "OurLexend-Light", Helvetica, sans-serif;
}

.w-400 {
  font-family: "OurLexend-Regular", Helvetica, sans-serif;
}

.w-600 {
  font-family: "OurLexend-Medium", Helvetica, sans-serif;
}

.w-800 {
  font-family: "OurLexend-SemiBold", Helvetica, sans-serif;
}

.w-1000 {
  font-family: "OurLexend-ExtraBold", Helvetica, sans-serif;
}

.w-1200 {
  font-family: "OurLexend-ExtraBold", Helvetica, sans-serif;
}

//font-weights
.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.text-gray-cool {
  color: $gray-cool-700;
}

.text-red-500 {
  color: $red-500;
}

.text-gray-500 {
  color: $gray-500;
}

.text-gray-600 {
  color: $grey-600;
}

.text-red-700 {
  color: $red-700;
}

.text-green-700 {
  color: $tri-green;
}

.sushi-gray-dark {
  color: $sushi-gray-dark;
}

.text-white {
  color: white;
}

//widths
.wd-100 {
  width: 100%;
}

.wd-90 {
  width: 90%;
}

.wd-50 {
  width: 50%;
}

.wd-33 {
  width: 33.33%;
}

.wd-70 {
  width: 70%;
}

.wd-45 {
  width: 45% !important;
}

//heights
.hv-100 {
  height: 100vh;
}

.greyScale {
  filter: grayscale(1);
  opacity: 0.5;
}

.lightScale {
  opacity: 0.7;
}

.medium-dark-gray {
  color: #828282;
}

.ml-10 {
  padding-left: 45px;
}

.cn-table-class{
  justify-content: space-between ;
  display: flex;
  width: 100%;
}

.dotted-line {
  width: 100%;
  height: 0;
  border-top: 2px dotted #EBEBEB;
  margin-top: 5px;
  margin-bottom: 5px;
}

.invoice-div {
  width: 550px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: none;
}

.invoice-sub-div {
  padding-bottom: 1px;
  display: flex;
  flex-direction: row;
  // margin-bottom: 5px;
}

.invoice-key-span {
  width: 30%;
  font-size: 12px;
  margin-left: 10px;
}

.text-green {
  color: #24963F;
}

.text-red {
  color: #E86C37;
}

.pointer {
  cursor: pointer !important;
}

.relative {
  position: relative;
}