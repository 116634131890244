@import "./variables.scss";

.thin-sushi-btn {
  width: 120px !important;
  height: 48px;
  > span {
    padding: 5px 6px;
    height: 48px;
    align-items: center;
    background: $red-white;
    border-radius: 8px;
    position: relative;
  }
}

.activated-btn {
  width: 120px;
  height: 48px;
  color: $white;
  background-color: $red-500;
  border-radius: 8px;
}

.deactivated-btn {
  width: 120px;
  height: 48px;
  color: $white;
  background-color: $grey-300;
  border-radius: 8px;
}

.verifyOtpButton {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 37%;
  height: 48px;
  border-radius: 8px;
  > span {
    border-radius: 8px;
  }
}

.textButton {
  background: none;
  cursor: pointer;
  border: none;
}

.sendOtpButton {
  margin-top: 1rem;
  width: 37%;
  height: 48px;
  > span {
    border-radius: 8px;
  }
  margin-bottom:3rem;
}
