.d-flex {
  display: flex;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.text-align-center {
  text-align: center;
}

//margins
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mr-32 {
  margin-right: 32px;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mt-20 {
  margin-top: 20px;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mb-4d5 {
  margin-bottom: 2rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 12rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.px-4Dot5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.px5{
  padding-right: 1.3rem !important;
  padding-left: 1.3rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.mt-7 {
  margin-top: 7.5rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.mbn-3 {
  margin-bottom: -1rem !important;
}

.y-scroll {
  overflow-y: scroll;
  height: calc(100% - 1px);
}

.y-scroll-80 {
  overflow-y: scroll;
  height: 83%;
}

.infinite-scroll {
  background: #f8f8fb;
  position: fixed;
  width:100%;
}
.x-scroll {
  overflow-x: scroll;
  width: 100vw;
}

.hide-pdf-container {
  position: absolute;
  left: -1000%;
  top: -1000%;
  max-height: 100px;
  overflow: scroll;
  width: 600px !important;
}

.default-invoice {
  color : black !important;
}

.container {
  position: relative;
  height: 100vh;
}

.topDiv {
  position: fixed;
  top: 0;
}

.bottomDiv {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.width-max {
  width: 1300px !important;
}

.wd-po-card {
  width: calc(100% - 40px);
}

.width-half {
    width: 45% !important
}

.bg-white {
    background: #fff !important
}

.border-red {
    border: 1px solid #ef4f5f !important
}

.scrollable-div {
    height: 85vh;
    width: auto;
    overflow-y: auto;
    padding-top: 2vh;
}

.fixed-top {
    position: fixed;
    width: 100vw;
}

.positiom-fixed {
  position: fixed;
}

.invoice-form {
    border-radius: 20px;
}

.scroll {
    height: 100% !important;
    overflow: scroll;
}

.rectangle {
    width: 100px;
    height: 100px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 15px;
}

.image-preview {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius:15px;
}

.image-preview-1 {
  max-width: 100%;
  max-height: 100%;
  width: 35px;
  height: auto;
  object-fit: contain;
  border-radius:15px;
}

.rectangle p {
    margin: 10px 0;
    text-align: center;
}

.po-header {
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
}

.po-content {
    position: absolute;
    top: 65px;
    bottom: 80px;
    width: 100%;
    overflow-y: scroll;
}

.po-bottom {
    position: absolute;
    height: 80px;
    bottom: 0;
    width: 100%;
}

.image-container {
  width: 300px;
  height: 200px;
  border: 2px solid #000;
  border-radius: 20px;
  background-size: cover;
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #808080;
}

.img-rectangle {
    width: 70px;
    height: 70px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 15px;
}

.box {
  width: 70px;
  height: 70px;
  border: 2px dotted red;
  border-radius: 20px;
  text-align: center;
  background: #FFF5F6;
}

.icon {
  font-size: 10px;
  margin-top: 20px;
}

.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #d3d3d3;
    background: transparent;
}

.home-Header {
    height: 65px;
    width: 100%;
}

.header-content {
    position: absolute;
    top: 115px;
    bottom: 0px;
    overflow-y: scroll;
    background: #f8f8fb;
    padding: 1em;
    width: 100%;
}

.circle-small {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid #d3d3d3;
    background: transparent;
}

.text-align-r {
    text-align: right;
}

.off-white {
  background: #f8f8fb;
}

.invalid-image-box {
  width: 250px;
  height: 50px;
  background-color: #F2F4F7;
  padding: 10px;
  position: relative;
  border-radius: 15px;
}

.green-color {
  color: #318616
}

.subtitle-background {
  background-color: #F2F4F7;
  padding: 10px;
  border-radius: 15px;
}

.bg-blue{
  background: blue;
}

.bg-grey{
  position: absolute;
  background: #f8f8fb;
  width: 100%;
  bottom: 0px;
  top: 0px;
}